@use "sass:meta";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,400;0,700;1,400&family=Noto+Serif:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-sans;
  }

  .container-sm {
    @apply w-full max-w-full mx-auto px-6 lg:max-w-2xl;
  }

  .container-xs {
    @apply w-full max-w-full mx-auto px-6 sm:max-w-lg;
  }

  .container:not(.modal-container) .container-sm, .container:not(.modal-container) .container-xs {
    @apply px-0;
  }

  .section-container {
    @apply max-w-5xl mx-auto text-center px-10
  }

  .modal {
    transition: opacity 0.25s ease;
  }

  .flex-fixed{
    flex: 0 0 auto;
  }

  body.modal-active {
    overflow-x: hidden;
    overflow-y: visible !important;
  }


  .inner_box.ghost {
    visibility: hidden;
    width: 100%;
    top: 0;
    left: 0;
    position: relative;
  }

  .adgv-datepicker {
    @include meta.load-css("datepicker/datepicker.scss");
  }

  .adgv-verification-input {
    @include meta.load-css("verification-input.scss");
    .vi__wrapper {
      @apply w-auto
    }
  }
  .slide{
    a{
      text-decoration: underline;
    }
  }
}
